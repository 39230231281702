<template>
  <div class="temp">
    <p>NOT FOUND</p>
    <!-- todo add button to go to dashboard -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.temp {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
</style>
